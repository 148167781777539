// eslint-disable-next-line
import Vue from 'vue'
import router from './router';
import createStore from './store'
import App from './App.vue'
const store = createStore;
import axios from "./http-common";
Vue.prototype.$http = axios;
import authentication from './authentication';
// eslint-disable-next-line
import styles from './assets/styles/styles.scss';

// eslint-disable-next-line
authentication.initialize().then(_ => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
});