import Vue from 'vue';
import Router from 'vue-router';
import authentication from '../authentication';
Vue.use(Router);

let router = new Router({
    mode: 'history',
    routes: [{
        path: '',
        name: 'home',
        redirect: '/formulation-mapping'
    }, {
        path: '/formulation-mapping',
        name: 'formulationmapping',
        component: () => import( /* webpackPrefetch: true */ '../components/formulation-mapping/FormulationMapping'),
        meta: {
            requiresAuth: true
        },
        children: [{
            path: '/formulation-mapping/additionalinfo',
            name: 'additionalinfo',
            component: () => import( /* webpackChunkName: "mapping" */ '../components/formulation-mapping/AdditionalInformation')
        }, {
            path: '/formulation-mapping/productmapping',
            name: 'productmapping',
            component: () => import( /* webpackChunkName: "mapping" */ '../components/formulation-mapping/ProductMapping')
        }, {
            path: '/formulation-mapping/comparasion',
            name: 'comparasion',
            component: () => import( /* webpackChunkName: "mapping" */ '../components/formulation-mapping/Comparasion')
        },]
    },
    {
        path: '/product-details',
        name: 'productdetails',
        component: () => import( /* webpackPrefetch: true */ '../components/product-details/ProductDetails'),
        meta: {
            requiresAuth: true
        },
        children: [{
            path: '/product-details/generalinfo',
            name: 'generalinfo',
            component: () => import( /* webpackChunkName: "product" */ '../components/product-details/GeneralInformation')
        }, {
            path: '/product-details/assignedformulation',
            name: 'assignedformulation',
            component: () => import( /* webpackChunkName: "product" */ '../components/product-details/AssignedFormulation')
        },]
    }, {
        path: '/reports',
        name: 'reports',
        component: () => import( /* webpackPrefetch: true */ '../components/reports/Report'),
        meta: {
            requiresAuth: true
        }, children: [{
            path: '/reports/nominalcompositionreport',
            name: 'nominalcompositionreport',
            component: () => import( /* webpackChunkName: "product" */ '../components/reports/NominalCompositionReport')
        }, {
            path: '/reports/cucreport',
            name: 'cucreport',
            component: () => import( /* webpackChunkName: "product" */ '../components/reports/CucReport')
        },
        {
            path: '/reports/productreport',
            name: 'productreport',
            component: () => import( /* webpackChunkName: "product" */ '../components/reports/ProductReport')
        },]
    },
    {
        path: '/uam/users',
        name: 'uam',
        component: () => import( /* webpackPrefetch: true */ '../components/uam/uam.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/unauthorised',
        name: 'unauthorised',
        component: () => import( /* webpackPrefetch: true */ '../components/Unauthorised.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/null',
        name: 'null',
        redirect: '/formulation-mapping'
    },
    {
        path: "**",
        name: "http404",
        component: () => import( /* webpackPrefetch: true */ '../components/Notfound'),
    }
    ],
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (authentication.isAuthenticated()) {
            next();
        } else {
            authentication.login();
        }
    } else {
        next();
    }
});

export default router;