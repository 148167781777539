import axios from "../http-common";
import loggerService from "./logger";
import authentication from "../authentication";

export default {
    fetchStatus() {
        return axios
            .get(`${process.env.VUE_APP_API_URL}/FormulationMapping/GetFormulationStatus`)
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    fetchUserRole() {
        return axios.get(
                `${
              process.env.VUE_APP_API_URL
            }/FormulationMapping/GetLoginUserDetail/${authentication.getUserEmail()}`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    fetchCountries() {
        return axios.get(
                `${
              process.env.VUE_APP_API_URL
            }/FormulationMapping/GetCountriesByUser/${authentication.getUserEmail()}`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    }
}