import {
    Bus
} from "../eventBus";

export default {
    logError(message) {
        console.log(message);
        Bus.$emit("notification", {
            text: message,
            type: "error",
        });
    },
    logError600(message) {
        console.log(message);
        Bus.$emit("notification", {
            text: message,
            type: "error",
        }, 6000);
    },
    logInfo(message) {
        console.log(message);
        Bus.$emit("notification", {
            text: message,
            type: "info",
        });
    },
    logInfo600(message) {
        console.log(message);
        Bus.$emit("notification", {
            text: message,
            type: "info",
        }, 6000);
    },
    logSuccess(message) {
        console.log(message);
        Bus.$emit("notification", {
            text: message,
            type: "success",
        }, 6000);
    }
}