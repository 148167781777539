var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("SideNavigation"),
      _c("Header"),
      _c(
        "div",
        { staticClass: "content-container", class: { open: _vm.isMenuOpen } },
        [_c("router-view")],
        1
      ),
      _c("ModalRoot"),
      _c("Notification")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }