<template>
  <div id="app">
    <SideNavigation />
    <Header />
    <div class="content-container" :class="{ open: isMenuOpen }">
      <router-view />
    </div>
    <ModalRoot />
    <Notification />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Header: () =>
      import(/* webpackPrefetch: true */ "./components/header/Header"),
    SideNavigation: () =>
      import(/* webpackPrefetch: true */ "./components/sidenav/SideNavigation"),
    ModalRoot: () =>
      import(/* webpackPrefetch: true */ "./components/modal-root/ModalRoot"),
    Notification: () =>
      import(/* webpackPrefetch: true */ "./components/common/Notification"),
  },
  created() {
    console.log(
      `Working on '${process.env.NODE_ENV}' mode @ ${process.env.VUE_APP_API_URL} -- `
    );

    this.getUeserRole();
    this.getStatus();
  },
  computed: {
    ...mapGetters({
      isMenuOpen: "GET_IS_MENU_OPEN",
    }),
  },
  methods: {
    ...mapActions(["fetchStatus", "fetchUserRole"]),
    getStatus() {
      this.fetchStatus();
    },
    getUeserRole() {
      this.fetchUserRole();
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  transition: margin-left 0.5s;
}
.content-container.open {
  transition: margin-left 0.5s;
}
</style>
