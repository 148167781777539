import axios from "../http-common";
import loggerService from "./logger";
import authentication from "../authentication";

export default {
    getFormulationMappings(payLoad) {
        return axios
            .get(
                `${
            process.env.VUE_APP_API_URL
          }/FormulationMapping/GetFormulationMappingList/${authentication.getUserEmail()}/${
            payLoad.cucCode
          }/${payLoad.revisionNumber}/${payLoad.cucStatus
          }`)
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getCUCRevisions(code) {
        return axios
            .get(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetCUCRevisions/${code}`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    deleteFormulationMapping(id) {
        return axios
            .delete(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/DeleteFormulationMapping/${id}`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getCUCAdditionalInfo(revision) {
        return axios
            .get(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetCUAdditionalInfo/${revision.Id}/${revision.Value}`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getLanguagesByCountry(country) {
        return axios
            .get(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetLanguagesByCountryId/${country}`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getBrandsByCountry(country) {
        return axios
            .get(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetBrandsByCountryId/${country}`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getVariationsProduct(payLoad) {
        return axios
            .get(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetProductVariationListByProductId/${payLoad.productId}/${payLoad.languageId}/${payLoad.countryId}`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    addFormulationMapping(addMapping) {
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/AddFormulationMapping`,
                [addMapping]
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    updateFormulationMapping(updateMapping) {
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/UpdateFormulationMapping`,
                [updateMapping]
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getBrands() {
        return axios
            .get(
                `${
      process.env.VUE_APP_API_URL
    }/FormulationMapping/GetBrandsByUser/${authentication.getUserEmail()}`
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getProductFormulation(payLoad) {
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetProductForFormulation`,
                payLoad
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    getCountriesAndLanguagesByBrandAndUser(payLoad) {
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/GetCountriesAndLanguagesByBrandNameandUser`,
                payLoad
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    createProductByBrandName(payLoad) {
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/CreateProductByBrandName`,
                payLoad
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    },
    updateProductAndVariations(payLoad, isAddVariation) {
        return axios
            .put(
                `${process.env.VUE_APP_API_URL}/FormulationMapping/UpdateProductandVariations/${isAddVariation}`,
                payLoad
            )
            .catch((error) => {
                loggerService.logError(error);
            });
    }
}