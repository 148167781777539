import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
import commanService from "../services";
import formulationMappingService from "../services/formulationMappings";
import loggerService from "../services/logger";

function createStore() {
    return new Vuex.Store({
        state: () => ({
            isMenuOpen: false,
            status: [],
            isFiltered: false,
            isLanguageType: null,
            isProductType: null,
            additionalInformation: null,
            products: [],
            product: {},
            countries: [],
            brands: [],
            brandInReports: "",
            countriesInReports: "",
            comparisonData: null,
            CucCodePrimary: null,
            CucCodeSecondary: null,
            CucStatus: null,
            RevisionPrimary: null,
            RevisionSecondary: null,
            userRole: null,
            formulation: null,
            formulations: [],
            formulationInProduct: null,
            user: {
                isDiabled:null,
                UserName: null,
                UserEmail: null,
                UserId: 0,
                RoleId:null,
                selectedCountry: [],
                selectedRole: null,
                selectedStatus: null,
                IsCreateUser:null
            }
        }),
        actions: {
            async fetchCountries({
                commit
            }) {
                try {
                    const {
                        data
                    } = await commanService.fetchCountries();
                    commit('SET_COUNTRIES', data);
                } catch (error) {
                    loggerService.logError(error);
                }
            },
            async fetchStatus({
                commit
            }) {
                try {
                    const {
                        data
                    } = await commanService.fetchStatus();
                    commit('SET_STATUS', data)
                } catch (error) {
                    loggerService.logError(error);
                }
            },
            async fetchUserRole({
                commit
            }) {
                try {
                    const {
                        data
                    } = await commanService.fetchUserRole();
                    const userInfo = {
                        role: data.length === 0 ? "" : data[0].Role_Name,
                        userCountries: data.length === 0 ? "" : data[0].UserCountries,
                        isUserAccess: data.length === 0 ? false : true,
                        isUser: data.length === 0 ?
                            "" : data[0].Role_Name === "User" ?
                            true : false,
                    };
                    commit('SET_USER_ROLE', userInfo)
                } catch (error) {
                    loggerService.logError(error);
                }
            },
            async fetchFormulations({
                commit
            }, payLoad) {
                try {
                    const {
                        data
                    } = await formulationMappingService.getFormulationMappings(payLoad);
                    commit('SET_FORMULATIONS', data)
                } catch (error) {
                    loggerService.logError(error);
                }
            },
        },
        mutations: {
            SET_IS_MENU_OPEN: (state, value) => {
                state.isMenuOpen = value;
            },
            SET_STATUS: (state, value) => {
                state.status = value;
            },
            SET_IS_FILTERED: (state, value) => {
                state.isFiltered = value;
            },
            SET_IS_LANGUAGE_TYPE: (state, value) => {
                state.isLanguageType = value;
            },
            SET_IS_PRODUCT_TYPE: (state, value) => {
                state.isProductType = value;
            },
            SET_ADDITIONAL_INFORMATION: (state, value) => {
                state.additionalInformation = value;
            },
            SET_ADD_PRODUCT: (state, value) => {
                state.products = [...value, ...state.products];
            },
            SET_UPDATE_PRODUCT: (state, value) => {
                state.product = value;
            },
            SET_COUNTRIES: (state, value) => {
                state.countries = value;
            },
            SET_PRODUCTS: (state, value) => {
                state.products = value;
            },
            SET_CUC_CODE_PRIMARY: (state, value) => {
                state.CucCodePrimary = value;
            },
            SET_CUC_CODE_SECONDARY: (state, value) => {
                state.CucCodeSecondary = value;
            },
            SET_CUC_STATUS: (state, value) => {
                state.CucStatus = value;
            },
            SET_REVISION_PRIMARY: (state, value) => {
                state.RevisionPrimary = value;
            },
            SET_REVISION_SECONDARY: (state, value) => {
                state.RevisionSecondary = value;
            },
            SET_BRAND_IDS: (state, value) => {
                state.brands = value;
            },
            SET_USER_ROLE: (state, value) => {
                state.userRole = value;
            },
            SET_FORMULATION: (state, value) => {
                state.formulation = value;
            },
            SET_FORMULATIONS: (state, value) => {
                state.formulations = value;
            },
            SET_FORMULATION_IN_PRODUCT: (state, value) => {
                state.formulationInProduct = value;
            },
            SET_BRAND_IN_REPORTS: (state, value) => {
                state.brandInReports = value;
            },
            SET_COUNTRIES_IN_REPORTS: (state, value) => {
                state.countriesInReports = value;
            },
            SET_USER_DETAILS: (state, value) => {
                state.user = value;
            }
        },
        getters: {
            GET_STATUS: state => state.status,
            GET_IS_FILTERED: state => state.isFiltered,
            GET_IS_LANGUAGE_TYPE: state => state.isLanguageType,
            GET_IS_PRODUCT_TYPE: state => state.isProductType,
            GET_ADDITIONAL_INFORMATION: state => state.additionalInformation,
            GET_PRODUCTS: state => state.products,
            GET_UPDATE_PRODUCT: state => state.product,
            GET_COUNTRIES: state => state.countries,
            GET_CUC_CODE_PRIMARY: state => state.CucCodePrimary,
            GET_CUC_CODE_SECONDARY: state => state.CucCodeSecondary,
            GET_CUC_STATUS: state => state.CucStatus,
            GET_REVISION_PRIMARY: state => state.RevisionPrimary,
            GET_REVISION_SECONDARY: state => state.RevisionSecondary,
            GET_BRAND_IDS: state => state.brands,
            GET_USER_ROLE: state => state.userRole,
            GET_FORMULATION: state => state.formulation,
            GET_FORMULATIONS: state => state.formulations,
            GET_FORMULATION_IN_PRODUCT: state => state.formulationInProduct,
            GET_IS_MENU_OPEN: state => state.isMenuOpen,
            GET_BRAND_IN_REPORTS: state => state.brandInReports,
            GET_COUNTRIES_IN_REPORTS: state => state.countriesInReports,
            GET_USER_DETAILS: state => state.user
        }
    });
}
export default createStore;