import AuthenticationContext from 'adal-angular/lib/adal.js';

const config = {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    cacheLocation: 'localStorage',
    graphScopes: ['user.read']
};

export default {
    authenticationContext: null,
    initialize() {
        this.authenticationContext = new AuthenticationContext(config);
        // eslint-disable-next-line
        return new Promise((resolve, reject) => {
            if (this.authenticationContext.isCallback(window.location.hash) || window.self !== window.top) {
                this.authenticationContext.handleWindowCallback();
            } else {
                let user = this.authenticationContext.getCachedUser();
                if (user) {
                    resolve();
                } else {
                    this.login();
                }
            }
        });
    },

    acquireToken() {
        return new Promise((resolve, reject) => {
            this.authenticationContext.acquireToken(config.clientId, (error, token) => {
                if (error || !token) {
                    return reject(error);
                } else {
                    return resolve(token);
                }
            });
        });
    },

    // acquireTokenRedirect() {
    //     this.authenticationContext.acquireTokenRedirect('<azure active directory resource id>');
    // },

    isAuthenticated() {
        localStorage.setItem("token", this.authenticationContext.getCachedToken(config.clientId));
        if (this.authenticationContext.getCachedToken(config.clientId)) {
            return true;
        }
        return false;
    },

    getUserProfile() {
        return this.authenticationContext.getCachedUser().profile;
    },
    getUserEmail() {
        const user = this.authenticationContext.getCachedUser().profile;
        return user.unique_name;
    },
    getUserFirstname() {
        const user = this.authenticationContext.getCachedUser().profile;
        return user.given_name;
    },
    getUserLastname() {
        const user = this.authenticationContext.getCachedUser().profile;
        return user.family_name;
    },
    accessToken() {
        return localStorage.getItem('adal.idtoken');
    },
    login() {
        this.authenticationContext.login();
    },
    logout() {
        this.authenticationContext.logOut();
    },
    getGraph() {
        const user = this.authenticationContext.getCachedUser().profile;
        let headers = new Headers();
        const bearer = "Bearer " + user.aio;
        headers.append("Authorization", bearer);
        const options = {
            method: "GET",
            headers: headers
        };
        const graphEndpoint = "https://graph.microsoft.com/v1.0/me";

        fetch(graphEndpoint, options)
            .then(resp => {
                console.log("***", resp)
            });
    }
}